import http from "@/common/utils/http-common-utils";
import { useAxios } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/customer/constants/api.router.constants";
import { IRoomParams } from "@/customer/typings";

const RoomServices = {
  assign: async (params: IRoomParams, onSuccess: () => void, onError: () => void) => {
    const { execute } = useAxios(`${ApiRouterConstants.API_ROOMS}/assign`, { method: "POST", params: params }, http, {
      immediate: false,
      onSuccess,
      onError,
    });
    return await execute();
  },
};

export default RoomServices;
