import { MaybeRefOrGetter } from "@vueuse/core";
import type { Rule, Rules } from "async-validator";

type IFormRuleValidation = {
  firstTagName: string;
  secondTagName: string;
  color: string;
  pinned: boolean;
};

const rules = (data: IFormRuleValidation): MaybeRefOrGetter<Record<string, Rule | Rules>> => {
  const { firstTagName, secondTagName, pinned } = data;
  const lengthLimit = pinned ? 4 : 10;

  return {
    firstTagName: [
      { required: true, message: "タグ名を入力してください" },
      {
        validator: () => (firstTagName?.length ?? 0) <= lengthLimit,
        message: `タグ名を${lengthLimit}文字以内で入力してください`,
      },
    ],
    secondTagName: [
      {
        validator: () => (secondTagName?.length ?? 0) <= lengthLimit,
        message: `タグ名を${lengthLimit}文字以内で入力してください`,
      },
    ],
    color: [{ required: true, message: "タグの色を入力してください" }],
  };
};

export default rules;
