import ApiRouterConstants from "@/customer/constants/api.router.constants";
import http from "@/common/utils/http-common-utils";
import { useAxios } from "@vueuse/integrations";
import { ApiBaseReq } from "@/customer/typings";

const ServiceTagService = {
  fetchServiceTag: async (params: ApiBaseReq) => {
    const { execute } = useAxios(ApiRouterConstants.API_SERVICE_TAGS, { method: "GET", params: params }, http, {
      immediate: false,
    });
    return await execute();
  },
};

export default ServiceTagService;
