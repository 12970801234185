enum MessageSenderEnum {
  clinic = "from_clinic",
  customer = "from_customer",
}

enum MessageTypeEnum {
  text = "text",
  audio = "audio",
  image = "image",
  sticker = "sticker",
  video = "video",
}

enum ImageMessageTypeEnum {
  image = "image",
  sticker = "sticker",
  emoji = "emoji",
}

export { MessageSenderEnum, MessageTypeEnum, ImageMessageTypeEnum };
