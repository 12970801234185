enum WeekDayEnum {
  sunday = 0,
  monday = 1,
  tuesday = 2,
  wednesday = 3,
  thursday = 4,
  friday = 5,
  saturday = 6,
}

enum ClinicPartner {
  Unaffiliated = "unaffiliated",
  Ripicle = "ripicle",
  Medipo = "medipo",
}

export { WeekDayEnum, ClinicPartner };
