enum TagTypeEnum {
  manual = "manual",
  template = "template",
  temporary = "temporary",
  partner = "partner",
}

enum TagTypeOrderEnum {
  partner = 1,
  temporary = 2,
  manual = 3,
  template = 4,
}

enum TagTypeNameEnum {
  manual = "オリジナルタグ",
  template = "テンプレートタグ",
}

export { TagTypeEnum, TagTypeNameEnum, TagTypeOrderEnum };
