export const DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm";
export const DATE_FORMAT = "YYYY/MM/DD";
export const DATE_ISO_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm";
export const TIME_EMPTY_HOUR_FORMAT = "HH:00";
export const TIME_HOUR_FORMAT = "HH";
export const TIME_MINUTE_FORMAT = "mm";
export const DATETIME_ISO_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DAY_JP_FORMAT = "DD ddd";
export const LOCALE = "ja-JP";
export const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;
export const MILLISECONDS_MIN_SLOT_DEFAULT = 0;
export const MILLISECONDS_MAX_SLOT_DEFAULT = 24 * 60 * 60 * 1000;
export const MILLISECONDS_IN_HOUR = 60 * 60 * 1000;
export const MENU_NAME_LIMIT = 3;
export const WEEK_MENU_NAME_LIMIT = 10;
export const CUSTOMER_NAME_MENU_LIMIT = 4;
export const WeekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
