import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "@/customer/constants/api.router.constants";
import { ApiZipcodeShowReq, IZipcode } from "@/clinic/typings";
import { AxiosResponse } from "axios";
import { useAxios } from "@vueuse/integrations/useAxios";

const ZipcodeServices = {
  getZipCode: async (
    payload: ApiZipcodeShowReq,
    onSuccess: (res: any) => void,
    onError: (error: any) => void
  ): Promise<void> => {
    const { id } = payload;
    const { execute } = useAxios<AxiosResponse<IZipcode>>(
      `${ApiRouterConstants.API_ZIPCODES}/${id}`,
      {
        method: "GET",
      },
      http,
      {
        immediate: false,
        onSuccess: (response: any) => {
          if (onSuccess) onSuccess(response);
        },
        onError: (error: any) => {
          onError(error.response?.data?.errors);
        },
      }
    );
    await execute();
  },
};

export default ZipcodeServices;
