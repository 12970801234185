import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "@/customer/constants/api.router.constants";
import { ICalendarEvents, ICalendarParams } from "../typings";
import { AxiosResponse } from "axios";
import { StrictUseAxiosReturn, useAxios } from "@vueuse/integrations/useAxios";
import { AnyFn } from "@vueuse/core";

const CalendarServices = {
  fetchEvents: async (
    params: ICalendarParams,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<StrictUseAxiosReturn<AxiosResponse<ICalendarEvents[]>, AxiosResponse, ICalendarEvents[]>> => {
    const { staff_id } = params;
    const { execute } = useAxios<AxiosResponse<ICalendarEvents[]>>(
      ApiRouterConstants.API_CALENDARS_EVENTS,
      { method: "GET", params },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
};

export default CalendarServices;
