import { useCookies } from "@vueuse/integrations/useCookies";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import SignIn from "@/clinic/views/SignIn/index.vue";
import ForgotPassword from "@/clinic/views/ForgotPassword/index.vue";
import ResetPassword from "@/clinic/views/ResetPassword/index.vue";
import Dashboard from "@/clinic/views/Dashboard.vue";
import Campaign from "@/clinic/views/Campaign.vue";
import CampaignNew from "@/clinic/views/Campaign/new/index.vue";
import CampaignResult from "@/clinic/views/CampaignResult.vue";
import MainContent from "@/clinic/views/MainContent.vue";
import ClinicRouterConstants from "@/clinic/constants/router.constants";
import Booking from "@/clinic/views/Booking/index.vue";
import CampaignNodata from "@/clinic/views/CampaignNodata.vue";
import ClinicRoutine from "@/clinic/views/ClinicRoutine/index.vue";
import Contact from "@/clinic/views/Contact/index.vue";
import Customer from "@/clinic/views/Customer/index.vue";
import CustomerNew from "@/clinic/views/Customer/new.vue";
import CustomerEdit from "@/clinic/views/Customer/edit.vue";
import Setting from "@/clinic/views/Setting.vue";
import SegmentWorkflow from "@/clinic/views/SegmentWorkflow.vue";
import RichMenu from "@/clinic/views/RichMenu.vue";
import RichMenuRegistration from "@/clinic/views/RichMenuRegistration.vue";
import RichMenuEdit from "@/clinic/views/RichMenuEdit.vue";
import PostBack from "@/clinic/views/PostBackMessage/index.vue";
import PostBackNew from "@/clinic/views/PostBackMessage/new.vue";
import PostBackEdit from "@/clinic/views/PostBackMessage/edit.vue";
import MessageNew from "@/clinic/views/ScheduledMessage/new.vue";
import MessageEdit from "@/clinic/views/ScheduledMessage/edit.vue";
import MessageList from "@/clinic/views/ScheduledMessage/index.vue";
import DirectMessage from "@/clinic/views/DirectMessage/index.vue";
import StaffNew from "@/clinic/views/Staffs/new.vue";
import StaffList from "@/clinic/views/Staffs/index.vue";
import StaffEdit from "@/clinic/views/Staffs/edit.vue";
import NewMedicalMenu from "@/clinic/views/MedicalMenu/new.vue";
import EditingMedicalMenu from "@/clinic/views/MedicalMenu/edit.vue";
import MedicalMenuList from "@/clinic/views/MedicalMenu/index.vue";
import BookingSettingsList from "@/clinic/views/BookingSetting.vue";
import MedicalOptions from "@/clinic/views/MedicalOptions/index.vue";
import MedicalOptionNew from "@/clinic/views/MedicalOptions/Create.vue";
import MedicalOptionEdit from "@/clinic/views/MedicalOptions/Edit.vue";
import MedicalMenuCategories from "@/clinic/views/MedicalMenuCategories/index.vue";
import LiveMessageTemplates from "@/clinic/views/LiveMessageTemplates/index.vue";
import LiveMessageTemplateNew from "@/clinic/views/LiveMessageTemplates/new.vue";
import LiveMessageTemplateEdit from "@/clinic/views/LiveMessageTemplates/edit.vue";
import ManualTags from "@/clinic/views/ManualTags/index.vue";
import ClientDetailsList from "@/clinic/views/ClientDetail.vue";
import LineNotificationMessage from "@/clinic/views/LineNotificationMessage/index.vue";
import LineNotificationMessageNew from "@/clinic/views/LineNotificationMessage/new.vue";
import LineNotificationMessageEdit from "@/clinic/views/LineNotificationMessage/edit.vue";
import DistributionGroup from "@/clinic/views/DistributionGroup/index.vue";
import TaggedDelivery from "@/clinic/views/DistributionGroup/TaggedDeliverySummary.vue";
import NewDistributionGroup from "@/clinic/views/DistributionGroup/new.vue";
import RoutineDistributionGroup from "@/clinic/views/DistributionGroupRoutine/index.vue";
import NewRoutineDistributionGroup from "@/clinic/views/DistributionGroupRoutine/new.vue";
import EditRoutineDistributionGroup from "@/clinic/views/DistributionGroupRoutine/edit.vue";
import DistributionRoutineGroupDrafts from "@/clinic/views/DistributionGroupRoutine/drafts.vue";
import EditDistributionGroup from "@/clinic/views/DistributionGroup/edit.vue";
import DistributionGroupDrafts from "@/clinic/views/DistributionGroup/drafts.vue";
import TargetCustomers from "@/clinic/views/DistributionGroup/TargetCustomers.vue";
import DuplicateDistributionGroup from "@/clinic/views/DistributionGroup/Duplicate.vue";
import DuplicateRoutineDistributionGroup from "@/clinic/views/DistributionGroupRoutine/Duplicate.vue";
import StaffPriority from "@/clinic/views/StaffPriority/index.vue";
import ServiceTag from "@/clinic/views/ServiceTags/index.vue";
import Rooms from "@/clinic/views/Rooms/index.vue";
import ShiftTemplate from "@/clinic/views/ShiftTemplate/index.vue";
import PrivacyPolicy from "@/clinic/views/static/PrivacyPolicy.vue";
import TermsOfService from "@/clinic/views/static/TermsOfService.vue";
import CompanyProfile from "@/clinic/views/static/CompanyProfile.vue";
import BookingNotifications from "@/clinic/views/BookingNotifications/index.vue";
//Customer imports
import CustomerRouterConstants from "@/customer/constants/router.constants";
import CustomerMainContent from "@/customer/views/MainContent.vue";
import CustomerBookingWrapper from "@/customer/views/BookingWrapper.vue";
import CustomerBooking from "@/customer/components/Booking.vue";
import CustomerBookingMenu from "@/customer/components/BookingMenu.vue";
import CustomerBookingMenuCategory from "@/customer/components/BookingMenuCategory.vue";
import CustomerBookingOption from "@/customer/components/BookingOption.vue";
import CustomerBookingCalendar from "@/customer/components/BookingCalendar.vue";
import PersonalInfo from "@/customer/components/PersonalInfo.vue";
import ContentConfirmation from "@/customer/components/ContentConfirmation.vue";
import BookingSuccess from "@/customer/components/BookingSuccess.vue";
import BookingCancelSuccess from "@/customer/views/BookingCancelSuccess.vue";
import BookingHistory from "@/customer/components/BookingHistory.vue";
import CustomerInfos from "@/customer/components/CustomerInfos.vue";
import OtpForm from "@/customer/components/OtpForm.vue";
import AuthUtil from "@/common/utils/auth.util";
import RouterConstants from "@/clinic/constants/router.constants";
import RouterUtils from "@/common/utils/router.util";

import { useScriptTag } from "@vueuse/core";
import CommonConstants from "@/common/constants/app.constants";

const { get: getCookie } = useCookies();
const ifClientAuthenticated = (): boolean => {
  const token = getCookie("access_token");
  if (token && AuthUtil.checkAccessToken(token)) {
    useScriptTag(`${CommonConstants.BASE_URL}/js/channel-io.js`);
    return true;
  }

  RouterUtils.goTo(RouterConstants.SIGN_IN_ROUTER_NAME);
  return false;
};

const routes: Array<RouteRecordRaw> = [
  { path: "/", redirect: ClinicRouterConstants.SIGN_IN_ROUTER_PATH },
  {
    path: ClinicRouterConstants.SIGN_IN_ROUTER_PATH,
    name: ClinicRouterConstants.SIGN_IN_ROUTER_NAME,
    component: SignIn,
  },
  {
    path: ClinicRouterConstants.FORGOT_PASSWORD_ROUTER_PATH,
    name: ClinicRouterConstants.FORGOT_PASSWORD_ROUTER_NAME,
    component: ForgotPassword,
  },
  {
    path: ClinicRouterConstants.RESET_PASSWORD_ROUTER_PATH,
    name: ClinicRouterConstants.RESET_PASSWORD_ROUTER_NAME,
    component: ResetPassword,
  },
  {
    path: ClinicRouterConstants.CLINIC_ROOT_ROUTER_PATH,
    component: MainContent,
    children: [
      {
        path: ClinicRouterConstants.DASHBOARD_ROUTER_PATH,
        name: ClinicRouterConstants.DASHBOARD_ROUTER_NAME,
        component: Dashboard,
      },
      {
        path: ClinicRouterConstants.PRIVACY_POLICY_ROUTER_PATH,
        name: ClinicRouterConstants.PRIVACY_POLICY_ROUTER_NAME,
        component: PrivacyPolicy,
      },
      {
        path: ClinicRouterConstants.TERMS_OF_SERVICE_ROUTER_PATH,
        name: ClinicRouterConstants.TERMS_OF_SERVICE_ROUTER_NAME,
        component: TermsOfService,
      },
      {
        path: ClinicRouterConstants.COMPANY_PROFILE_ROUTER_PATH,
        name: ClinicRouterConstants.COMPANY_PROFILE_ROUTER_NAME,
        component: CompanyProfile,
      },
      {
        path: ClinicRouterConstants.BOOKING_ROUTER_ROUTER_PATH,
        name: ClinicRouterConstants.BOOKING_ROUTER_NAME,
        component: Booking,
      },
      {
        path: ClinicRouterConstants.CLINIC_DAILY_ROUTINE_ROUTER_PATH,
        name: ClinicRouterConstants.CLINIC_DAILY_ROUTINE_ROUTER_NAME,
        component: ClinicRoutine,
      },
      {
        path: ClinicRouterConstants.CAMPAIGN_ROUTER_PATH,
        name: ClinicRouterConstants.CAMPAIGN_ROUTER_NAME,
        component: Campaign,
      },
      {
        path: ClinicRouterConstants.CAMPAIGN_NEW_ROUTER_PATH,
        name: ClinicRouterConstants.CAMPAIGN_NEW_ROUTER_NAME,
        component: CampaignNew,
      },
      {
        path: ClinicRouterConstants.CAMPAIGN_RESULT_ROUTER_PATH,
        name: ClinicRouterConstants.CAMPAIGN_RESULT_ROUTER_NAME,
        component: CampaignResult,
      },
      {
        path: ClinicRouterConstants.CAMPAIGN_NODATA_ROUTER_PATH,
        name: ClinicRouterConstants.CAMPAIGN_NODATA_ROUTER_NAME,
        component: CampaignNodata,
      },
      {
        path: ClinicRouterConstants.CONTACT_ROUTER_PATH,
        name: ClinicRouterConstants.CONTACT_ROUTER_NAME,
        component: Contact,
      },
      {
        path: ClinicRouterConstants.CUSTOMER_ROUTER_PATH,
        name: ClinicRouterConstants.CUSTOMER_ROUTER_NAME,
        component: Customer,
      },
      {
        path: ClinicRouterConstants.TAGGED_DELIVERY_ROUTER_PATH,
        name: ClinicRouterConstants.TAGGED_DELIVERY_ROUTER_NAME,
        component: TaggedDelivery,
      },
      {
        path: ClinicRouterConstants.CUSTOMER_CREATE_ROUTER_PATH,
        name: ClinicRouterConstants.CUSTOMER_CREATE_ROUTER_NAME,
        component: CustomerNew,
      },
      {
        path: ClinicRouterConstants.CUSTOMER_EDIT_ROUTER_PATH,
        name: ClinicRouterConstants.CUSTOMER_EDIT_ROUTER_NAME,
        component: CustomerEdit,
      },
      {
        path: ClinicRouterConstants.SETTING_ROUTER_ROUTER_PATH,
        name: ClinicRouterConstants.SETTING_ROUTER_NAME,
        component: Setting,
      },
      {
        path: ClinicRouterConstants.SEGMENT_WORKFLOW_ROUTER_PATH,
        name: ClinicRouterConstants.SEGMENT_WORKFLOW_ROUTER_NAME,
        component: SegmentWorkflow,
      },
      {
        path: ClinicRouterConstants.RICH_MENU_ROUTER_PATH,
        name: ClinicRouterConstants.RICH_MENU_ROUTER_NAME,
        component: RichMenu,
      },
      {
        path: ClinicRouterConstants.RICH_MENU_REGISTRATION_ROUTER_PATH,
        name: ClinicRouterConstants.RICH_MENU_REGISTRATION_ROUTER_NAME,
        component: RichMenuRegistration,
      },
      {
        path: ClinicRouterConstants.RICH_MENU_EDIT_ROUTER_PATH,
        name: ClinicRouterConstants.RICH_MENU_EDIT_ROUTER_NAME,
        component: RichMenuEdit,
      },
      {
        path: ClinicRouterConstants.POSTBACK_ROUTER_PATH,
        name: ClinicRouterConstants.POSTBACK_ROUTER_NAME,
        component: PostBack,
      },
      {
        path: ClinicRouterConstants.NEW_POSTBACK_ROUTER_PATH,
        name: ClinicRouterConstants.NEW_POSTBACK_ROUTER_NAME,
        component: PostBackNew,
      },
      {
        path: ClinicRouterConstants.EDIT_POSTBACK_ROUTER_PATH,
        name: ClinicRouterConstants.EDIT_POSTBACK_ROUTER_NAME,
        component: PostBackEdit,
      },
      {
        path: ClinicRouterConstants.MESSAGE_NEW_ROUTER_PATH,
        name: ClinicRouterConstants.MESSAGE_NEW_ROUTER_NAME,
        component: MessageNew,
      },
      {
        path: ClinicRouterConstants.MESSAGE_EDIT_ROUTER_PATH,
        name: ClinicRouterConstants.MESSAGE_EDIT_ROUTER_NAME,
        component: MessageEdit,
      },
      {
        path: ClinicRouterConstants.MESSAGE_ROUTER_PATH,
        name: ClinicRouterConstants.MESSAGE_ROUTER_NAME,
        component: MessageList,
      },
      {
        path: ClinicRouterConstants.DIRECT_MESSAGE_ROUTER_PATH,
        name: ClinicRouterConstants.DIRECT_MESSAGE_ROUTER_NAME,
        component: DirectMessage,
      },
      {
        path: ClinicRouterConstants.STAFF_NEW_ROUTER_PATH,
        name: ClinicRouterConstants.STAFF_NEW_ROUTER_NAME,
        component: StaffNew,
      },
      {
        path: ClinicRouterConstants.STAFF_LIST_ROUTER_PATH,
        name: ClinicRouterConstants.STAFF_LIST_ROUTER_NAME,
        component: StaffList,
      },
      {
        path: ClinicRouterConstants.STAFF_EDIT_ROUTER_PATH,
        name: ClinicRouterConstants.STAFF_EDIT_ROUTER_NAME,
        component: StaffEdit,
      },
      {
        path: ClinicRouterConstants.MENU_REGISTRATION_ROUTER_PATH,
        name: ClinicRouterConstants.MENU_REGISTRATION_ROUTER_NAME,
        component: NewMedicalMenu,
      },
      {
        path: ClinicRouterConstants.MENU_EDIT_ROUTER_PATH,
        name: ClinicRouterConstants.MENU_EDIT_ROUTER_NAME,
        component: EditingMedicalMenu,
      },
      {
        path: ClinicRouterConstants.MENU_ROUTER_PATH,
        name: ClinicRouterConstants.MENU_ROUTER_NAME,
        component: MedicalMenuList,
      },
      {
        path: ClinicRouterConstants.BOOKING_SETTING_ROUTER_PATH,
        name: ClinicRouterConstants.BOOKING_SETTING_ROUTER_NAME,
        component: BookingSettingsList,
      },
      {
        path: ClinicRouterConstants.MEDICAL_OPTIONS_ROUTER_PATH,
        name: ClinicRouterConstants.MEDICAL_OPTIONS_ROUTER_NAME,
        component: MedicalOptions,
      },
      {
        path: ClinicRouterConstants.MEDICAL_OPTIONS_NEW_ROUTER_PATH,
        name: ClinicRouterConstants.MEDICAL_OPTIONS_NEW_ROUTER_NAME,
        component: MedicalOptionNew,
      },
      {
        path: ClinicRouterConstants.MEDICAL_OPTIONS_EDIT_ROUTER_PATH,
        name: ClinicRouterConstants.MEDICAL_OPTIONS_EDIT_ROUTER_NAME,
        component: MedicalOptionEdit,
      },
      {
        path: ClinicRouterConstants.MEDICAL_MENU_CATEGORY_ROUTER_PATH,
        name: ClinicRouterConstants.MEDICAL_MENU_CATEGORY_ROUTER_NAME,
        component: MedicalMenuCategories,
      },
      {
        path: ClinicRouterConstants.LIVE_MESSAGE_TEMPLATE_ROUTER_PATH,
        name: ClinicRouterConstants.LIVE_MESSAGE_TEMPLATE_ROUTER_NAME,
        component: LiveMessageTemplates,
      },
      {
        path: ClinicRouterConstants.LIVE_MESSAGE_TEMPLATE_NEW_ROUTER_PATH,
        name: ClinicRouterConstants.LIVE_MESSAGE_TEMPLATE_NEW_ROUTER_NAME,
        component: LiveMessageTemplateNew,
      },
      {
        path: ClinicRouterConstants.LIVE_MESSAGE_TEMPLATE_EDIT_ROUTER_PATH,
        name: ClinicRouterConstants.LIVE_MESSAGE_TEMPLATE_EDIT_ROUTER_NAME,
        component: LiveMessageTemplateEdit,
      },
      {
        path: ClinicRouterConstants.MANUAL_TAGS_ROUTER_PATH,
        name: ClinicRouterConstants.MANUAL_TAGS_ROUTER_NAME,
        component: ManualTags,
      },
      {
        path: ClinicRouterConstants.CLIENT_DETAILS_ROUTER_PATH,
        name: ClinicRouterConstants.CLIENT_DETAILS_ROUTER_NAME,
        component: ClientDetailsList,
      },
      {
        path: ClinicRouterConstants.LINE_NOTIFICATION_MESSAGE_NEW_ROUTER_PATH,
        name: ClinicRouterConstants.LINE_NOTIFICATION_MESSAGE_NEW_ROUTER_NAME,
        component: LineNotificationMessageNew,
      },
      {
        path: ClinicRouterConstants.LINE_NOTIFICATION_MESSAGE_EDIT_ROUTER_PATH,
        name: ClinicRouterConstants.LINE_NOTIFICATION_MESSAGE_EDIT_ROUTER_NAME,
        component: LineNotificationMessageEdit,
      },
      {
        path: ClinicRouterConstants.LINE_NOTIFICATION_MESSAGE_ROUTER_PATH,
        name: ClinicRouterConstants.LINE_NOTIFICATION_MESSAGE_ROUTER_NAME,
        component: LineNotificationMessage,
      },
      {
        path: ClinicRouterConstants.DISTRIBUTION_GROUP_ROUTER_PATH,
        name: ClinicRouterConstants.DISTRIBUTION_GROUP_ROUTER_NAME,
        component: DistributionGroup,
      },
      {
        path: ClinicRouterConstants.NEW_DISTRIBUTION_GROUP_ROUTER_PATH,
        name: ClinicRouterConstants.NEW_DISTRIBUTION_GROUPS_NAME,
        component: NewDistributionGroup,
      },
      {
        path: ClinicRouterConstants.DISTRIBUTION_ROUTINE_GROUP_ROUTER_PATH,
        name: ClinicRouterConstants.DISTRIBUTION_ROUTINE_GROUP_ROUTER_NAME,
        component: RoutineDistributionGroup,
      },
      {
        path: ClinicRouterConstants.NEW_DISTRIBUTION_ROUTINE_GROUP_ROUTER_PATH,
        name: ClinicRouterConstants.NEW_DISTRIBUTION_ROUTINE_GROUP_ROUTER_NAME,
        component: NewRoutineDistributionGroup,
      },
      {
        path: ClinicRouterConstants.EDIT_DISTRIBUTION_ROUTINE_GROUP_ROUTER_PATH,
        name: ClinicRouterConstants.EDIT_DISTRIBUTION_ROUTINE_GROUP_ROUTER_NAME,
        component: EditRoutineDistributionGroup,
      },
      {
        path: ClinicRouterConstants.DISTRIBUTION_GROUPS_ROUTINE_DRAFTS_PATH,
        name: ClinicRouterConstants.DISTRIBUTION_GROUPS_ROUTINE_DRAFTS_NAME,
        component: DistributionRoutineGroupDrafts,
      },
      {
        path: ClinicRouterConstants.EDIT_DISTRIBUTION_GROUP_ROUTER_PATH,
        name: ClinicRouterConstants.EDIT_DISTRIBUTION_GROUP_ROUTER_NAME,
        component: EditDistributionGroup,
      },
      {
        path: ClinicRouterConstants.DUPLICATE_DISTRIBUTION_GROUP_ROUTER_PATH,
        name: ClinicRouterConstants.DUPLICATE_DISTRIBUTION_GROUP_ROUTER_NAME,
        component: DuplicateDistributionGroup,
      },
      {
        path: ClinicRouterConstants.DUPLICATE_DISTRIBUTION_ROUTINE_GROUP_ROUTER_PATH,
        name: ClinicRouterConstants.DUPLICATE_DISTRIBUTION_ROUTINE_GROUP_ROUTER_NAME,
        component: DuplicateRoutineDistributionGroup,
      },
      {
        path: ClinicRouterConstants.DISTRIBUTION_GROUPS_DRAFTS_PATH,
        name: ClinicRouterConstants.DISTRIBUTION_GROUPS_DRAFTS_NAME,
        component: DistributionGroupDrafts,
      },
      {
        path: ClinicRouterConstants.TARGET_CUSTOMER_ROUTER_PATH,
        name: ClinicRouterConstants.TARGET_CUSTOMER_ROUTER_NAME,
        component: TargetCustomers,
      },
      {
        path: ClinicRouterConstants.STAFF_PRIORITIES_ROUTER_PATH,
        name: ClinicRouterConstants.STAFF_PRIORITIES_ROUTER_NAME,
        component: StaffPriority,
      },
      {
        path: ClinicRouterConstants.SERVICE_TAGS_ROUTER_PATH,
        name: ClinicRouterConstants.SERVICE_TAGS_ROUTER_NAME,
        component: ServiceTag,
      },
      {
        path: ClinicRouterConstants.BOOKING_NOTIFICATIONS_ROUTER_PATH,
        name: ClinicRouterConstants.BOOKING_NOTIFICATIONS_ROUTE_NAME,
        component: BookingNotifications,
      },
      {
        path: ClinicRouterConstants.ROOMS_ROUTER_PATH,
        name: ClinicRouterConstants.ROOMS_ROUTE_NAME,
        component: Rooms,
      },
      {
        path: ClinicRouterConstants.SHIFT_TEMPLATES_ROUTER_PATH,
        name: ClinicRouterConstants.SHIFT_TEMPLATES_ROUTE_NAME,
        component: ShiftTemplate,
        props: true,
      },
    ],
    beforeEnter: ifClientAuthenticated,
  },
  {
    path: CustomerRouterConstants.CUSTOMER_ROOT_PATH,
    name: CustomerRouterConstants.CUSTOMER_ROUTER_NAME,
    component: CustomerMainContent,
    redirect: CustomerRouterConstants.BOOKING_ROUTER_PATH,
    children: [
      {
        path: CustomerRouterConstants.BOOKING_HISTORY_ROUTER_PATH,
        name: CustomerRouterConstants.BOOKING_HISTORY_ROUTER_NAME,
        component: BookingHistory,
      },
      {
        path: CustomerRouterConstants.CUSTOMER_INFOS_ROUTER_PATH,
        name: CustomerRouterConstants.CUSTOMER_INFOS_ROUTER_NAME,
        component: CustomerInfos,
      },
      {
        path: CustomerRouterConstants.BOOKING_ROUTER_PATH,
        component: CustomerBookingWrapper,
        children: [
          {
            path: CustomerRouterConstants.OTP_FORM_PATH,
            name: CustomerRouterConstants.OTP_FORM_NAME,
            component: OtpForm,
          },
          {
            path: CustomerRouterConstants.BOOKING_ROUTER_PATH,
            name: CustomerRouterConstants.BOOKING_ROUTER_NAME,
            component: CustomerBooking,
          },
          {
            path: CustomerRouterConstants.BOOKING_MENU_CATEGORY_ROUTER_PATH,
            name: CustomerRouterConstants.BOOKING_MENU_CATEGORY_ROUTER_NAME,
            component: CustomerBookingMenuCategory,
          },
          {
            path: CustomerRouterConstants.BOOKING_MENU_ROUTER_PATH,
            name: CustomerRouterConstants.BOOKING_MENU_ROUTER_NAME,
            component: CustomerBookingMenu,
          },
          {
            path: CustomerRouterConstants.BOOKING_OPTION_ROUTER_PATH,
            name: CustomerRouterConstants.BOOKING_OPTION_ROUTER_NAME,
            component: CustomerBookingOption,
          },
          {
            path: CustomerRouterConstants.BOOKING_CALENDAR_ROUTER_PATH,
            name: CustomerRouterConstants.BOOKING_CALENDAR_ROUTER_NAME,
            component: CustomerBookingCalendar,
          },
          {
            path: CustomerRouterConstants.PERSONAL_INFO_ROUTER_PATH,
            name: CustomerRouterConstants.PERSONAL_INFO_ROUTER_NAME,
            component: PersonalInfo,
          },
          {
            path: CustomerRouterConstants.CONTENT_CONFIRMATION_ROUTER_PATH,
            name: CustomerRouterConstants.CONTENT_CONFIRMATION_ROUTER_NAME,
            component: ContentConfirmation,
          },
          {
            path: CustomerRouterConstants.BOOKING_SUCCESS_ROUTER_PATH,
            name: CustomerRouterConstants.BOOKING_SUCCESS_ROUTER_NAME,
            component: BookingSuccess,
          },
          {
            path: CustomerRouterConstants.BOOKING_CANCEL_SUCCESS_ROUTER_PATH,
            name: CustomerRouterConstants.BOOKING_CANCEL_SUCCESS_ROUTER_NAME,
            component: BookingCancelSuccess,
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
