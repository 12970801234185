import http from "@/common/utils/http-common-utils";
import { useAxios } from "@vueuse/integrations/useAxios";
import ApiRouterConstants from "@/customer/constants/api.router.constants";
import { IOtpParams } from "@/customer/typings";

const OtpServices = {
  resend: async (params: IOtpParams, onSuccess: () => void, onError: () => void) => {
    const { execute } = useAxios(ApiRouterConstants.API_OTP, { method: "POST", data: params }, http, {
      immediate: false,
      onSuccess,
      onError,
    });
    return await execute();
  },
};

export default OtpServices;
